.contact-banner-title {
  position: relative;
  text-align: center;
  color: white;
}

.contact-site-title {
  font-size: 32px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-family: "Anton";
}

* {
  box-sizing: border-box;
}

input[type="text"],
input[type="email"],
select,
textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
}

.modal {
  text-align: center;
  width: 300;
  height: 300;
}

.required {
  color: red;
}

label {
  padding: 12px 12px 12px 0;
  display: inline-block;
}

input[type="submit"] {
  background-color: #04aa6d;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  float: right;
}

input[type="submit"]:hover {
  background-color: #45a049;
}

.container {
  border-radius: 5px;
  background-color: #f2f2f2;
  padding: 20px;
}

.col-25 {
  float: left;
  width: 25%;
}

.col-75 {
  float: left;
  width: 75%;
  margin-top: 6px;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

form {
  margin: 10em;
}

/* Responsive layout - when the screen is less than 600px wide, make the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
  .col-25,
  .col-75,
  input[type="submit"] {
    width: 100%;
    margin-top: 0;
  }
}

.contact {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.contact-widgets {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2%;
}

.contact-widgets div {
  text-align: justify;
  padding-left: 2%;
  max-width: 230px;
}

.contact-widgets a {
  color: #04aa6d;
  text-decoration: none;
}

.contact-widgets a:hover {
  text-decoration: underline;
}
