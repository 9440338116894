.housecleaner-banner-title {
  position: relative;
  text-align: center;
  color: white;
  background-color: #ececec;
}

.housecleaner-site-title {
  font-size: 32px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-family: "Anton";
}

.housecleaner-steps {
  display: flex;
}

.step {
  padding: 10px;
  margin: 10px;
  display: flex;
  flex-direction: column;
}

.step-number {
  display: flex;
  justify-content: center;
  text-align: center;
  font-size: larger;
  font-weight: 900;
  text-decoration: underline;
}

.step-title {
  text-align: center;
  font-weight: bold;
  font-size: large;
  font-style: italic;
  margin: 15px;
}

.step-description {
  text-align: center;
}

.housecleaner-whyworkwithus-points {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.housecleaner-gallery {
  padding: 5%;
}

.housecleaner-images-beforeafter {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-items: center;
  align-items: center;
}

.housecleaner-oneImage {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5%;
}

.progressbar {
  display: flex;
  counter-reset: step;
}
.progressbar li {
  list-style-type: none;
  width: 25%;
  float: left;
  font-size: 12px;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  color: #7d7d7d;
}
.progressbar li:before {
  width: 30px;
  height: 30px;
  content: counter(step);
  counter-increment: step;
  line-height: 30px;
  border: 2px solid #7d7d7d;
  display: block;
  text-align: center;
  margin: 0 auto 10px auto;
  border-radius: 50%;
  background-color: white;
}
.progressbar li:after {
  width: 100%;
  height: 2px;
  content: "";
  position: absolute;
  background-color: #7d7d7d;
  top: 15px;
  left: -50%;
  z-index: -1;
}
.progressbar li:first-child:after {
  content: none;
}
.progressbar li.active {
  color: green;
}
.progressbar li.active:before {
  border-color: #55b776;
}
.progressbar li.active + li:after {
  background-color: #55b776;
}

.housecleaner-carousel {
  display: flex;
  justify-content: center;
  align-items: center;
}

.housecleaner-h2 {
  color: #3f82a7;
}

.button-afterbefore {
  height: 50px;
  background-color: #111827;
  border: 1px solid transparent;
  border-radius: 0.75rem;
  color: #ffffff;
  cursor: pointer;
  flex: 0 0 auto;
  font-family: "Inter var", ui-sans-serif, system-ui, -apple-system, system-ui,
    "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1.125rem;
  font-weight: 600;
  transition-duration: 0.2s;
  transition-property: background-color, border-color, color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  touch-action: manipulation;
}

.button-afterbefore:hover {
  background-color: #374151;
}

.button-afterbefore:focus {
  box-shadow: none;
  outline: 2px solid transparent;
  outline-offset: 2px;
}
