.head {
  position: relative;
  font-family: "Anton";
  text-align: center;
  letter-spacing: 0.5px;
  max-height: 427.84px;
}

.banner {
  width: 100%;
}

.site-title {
  font-size: 32px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-transform: uppercase;
  letter-spacing: 2px;
}

ul.topnav {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: rgb(32, 32, 32);
}

.navbar {
  display: flex;
  justify-content: space-between;
}

.navbar-buttons {
  display: flex;
  align-items: center;
  margin: auto;
}

ul.topnav li {
  float: left;
}

.title {
  background-color: rgb(0, 0, 0);
  float: left;
  display: block;
  color: white;
  text-align: center;
  text-decoration: none;
}

ul.topnav li a {
  display: block;
  color: white;
  text-align: center;
  padding: 16px 16px;
  text-decoration: none;
  font-weight: bold;
  font-size: larger;
  width: 180px;
  border-radius: 5px;
}

ul.topnav li a:hover {
  opacity: 0.5;
  transition-duration: 0.25s;
}

ul.topnav li a:hover:not(.active) {
  background-color: rgb(31, 31, 31);
}

ul.topnav li.right {
  float: right;
}

a.phone-style {
  border: solid 2px white;
  border-radius: 5px;
  padding: 12px;
  color: white;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  font-family: "Anton";
  letter-spacing: 1px;
  font-size: 16px;
  cursor: pointer;
}

a.phone-style:hover {
  border: solid 2px rgb(255, 255, 255);
  color: rgb(255, 255, 255);
  background-color: black;
}

@media screen and (max-width: 600px) {
  ul.topnav li.right,
  ul.topnav li {
    float: none;
  }
  .navbar {
    justify-content: center;
  }
}

.top-illu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
}

.top-illu-left {
  margin-left: 1%;
}

.top-illu-right {
  margin-right: 1%;
}

header {
  background-color: #e1e1e1;
}

#home.active {
  background-color: rgb(66, 30, 30);
}

#pruner.active {
  background-color: #66a165;
}

#scrapmerchant.active {
  background-color: #6f4d4d;
}

#housecleaner.active {
  background-color: #3f82a7;
}

#clearingservice.active {
  background-color: #ce5151;
}

#contact.active {
  background-color: #333;
}
