h2 {
  font-family: "Anton", sans-serif;
  color: rgb(102, 161, 101);
  letter-spacing: 2px;
  text-transform: uppercase;
}

body {
  font-family: "Open Sans", sans-serif;
}

p {
  line-height: 1.6;
}

.black-title {
  color: rgb(32, 32, 32);
}

.legalnotice-footer {
  bottom: 0;
  left: 0;
  right: 0;
  position: absolute;
}

.firstblock {
  background-color: #ececec;
  padding: 5%;
}

.thirdblock {
  background-color: #ececec;
  padding: 5%;
}

.fourthblock {
  background-color: white;
  padding: 5%;
}

.fifthblock {
  background-color: #ececec;
  padding: 5%;
}
