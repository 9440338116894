.scrapmerchant-banner-title {
  position: relative;
  text-align: center;
  color: white;
  background-color: #ececec;
}

.scrapmerchant-site-title {
  font-size: 32px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-family: "Anton";
}

.container_scrapmerchant {
  position: relative;
  text-align: center;
  color: white;
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: "Anton", sans-serif;
  font-size: 48px;
  letter-spacing: 4px;
}

.scrapmerchant-card {
  text-align: center;

  border-radius: 10px;
  background-color: white;
  margin: 10px;
  padding: 10px;

  box-shadow: 5px 5px 5px rgb(112, 112, 112);
  transition: transform 0.2s;
}

.scrapmerchant-card:hover {
  transform: scale(1.05);
}

.scrapmerchant-card-title {
  color: black;
  margin: 1rem auto;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1;
}

.scrapmerchant-h2 {
  color: #6f4d4d;
}

.scrapmerchant-gallery {
  background-color: #ececec;
  padding: 5%;
}