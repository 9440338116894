/* RESPONSIVE HOME */

@media only screen and (min-width: 768px) {
  /* For desktop: */
  .navbar-buttons {
    display: flex;
  }
  .navbar-3buttons {
    display: flex;
    flex-direction: column;
  }
  .top-illu-left {
    margin: auto;
    padding-left: 50px;
    padding-right: 50px;
  }
  .cards {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .card {
    height: 330px;
    border-radius: 10px;
    background-color: white;
    margin: 20px;
    position: relative;
    box-shadow: 5px 5px 5px rgb(112, 112, 112);
    transition: transform 0.2s;
  }
  .pruner-carousel-description {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .progressbar-container {
    display: none;
  }
}

@media only screen and (min-width: 1024px) {
  .card {
    height: 330px;
    border-radius: 10px;
    background-color: white;
    margin: 20px;
    position: relative;
    box-shadow: 5px 5px 5px rgb(112, 112, 112);
    transition: transform 0.2s;
  }
  .blocks {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
  }
  .pruner-carousel-description {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
  }
  .progressbar-container {
    display: block;
  }
}

@media only screen and (min-width: 1440px) {
  .navbar-3buttons {
    display: flex;
    flex-direction: row;
  }
  .cards {
    display: flex;
  }
  .card {
    width: 350px;
    height: 360px;
  }
  .blocks {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
  }
  .contact-blocks {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
  }
  .right-block img {
    width: 70%;
  }
  .left-block img {
    width: 70%;
  }
  .cards {
    display: flex;
    justify-content: center;
  }
  .housecleaner-working {
    display: grid;
    grid-template-columns: 1fr;
    padding: 5%;
    padding-top: 0;
  }
  .progressbar-container {
    width: 1200px;
    margin: 100px auto;
  }
}

/* RESPONSIVE PRUNER */

@media only screen and (min-width: 768px) {
  /* For desktop: */
  .illustrations {
    width: 816px;
  }
  .pruner-site-title {
    font-size: 26px;
  }
}

@media only screen and (min-width: 1024px) {
  .illustrations {
    width: 90%;
  }
  .pruner-site-title {
    font-size: 32px;
  }
}

@media only screen and (min-width: 1440px) {
}

/* RESPONSIVE scrapmerchant */

@media only screen and (min-width: 768px) {
  /* For desktop: */
}

@media only screen and (min-width: 1024px) {
  .scrapmerchant-cards {
    display: flex;
    justify-content: center;
  }
}

@media only screen and (min-width: 1440px) {
  .scrapmerchant-cards {
    display: flex;
    justify-content: center;
  }
}
