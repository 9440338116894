.home-banner-title {
  position: relative;
  text-align: center;
  color: white;
  background-color: #ececec;
}

.home-site-title {
  font-size: 32px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-family: "Anton";
}

.card:hover {
  transform: scale(1.05);
}

.card-header {
  padding: 0.02%;
  border-radius: 10px 10px 0px 0px;
  text-align: center;
  color: white;
  font-family: "Anton";
  letter-spacing: 1px;
  font-size: 18px;
}

#card-header-1 {
  background-color: #66a165;
}
#card-header-2 {
  background-color: #6f4d4d;
}
#card-header-3 {
  background-color: #3f82a7;
}
#card-header-4 {
  background-color: #ce5151;
}

.card-description {
  text-align: center;
  padding: 0px 10px 0px 10px;
}

.card-footer {
  text-align: center;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 10px;
}

.check {
  color: rgb(34, 199, 34);
  font-weight: 800;
}

.cross {
  color: rgb(199, 34, 34);
  font-weight: 800;
}

.know-more {
  border: none;
  border-radius: 5px;
  color: white;
  padding: 12px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  font-family: "Anton";
  letter-spacing: 1px;
  font-size: 16px;
  cursor: pointer;
  box-shadow: 5px 5px 5px rgb(168, 168, 168);
}

.know-more span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.2s;
}

.know-more span:after {
  content: "\00bb";
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.2s;
}

.know-more:hover span {
  padding-right: 25px;
}

.know-more:hover span:after {
  opacity: 1;
  right: 0;
}

#know-more-1 {
  background-color: #66a165;
  border: 2px solid #66a165;
}

#know-more-1:hover {
  background-color: white;
  color: #66a165;
}

#know-more-2 {
  background-color: #6f4d4d;
  border: 2px solid #6f4d4d;
}

#know-more-2:hover {
  background-color: white;
  color: #6f4d4d;
}

#know-more-3 {
  background-color: #3f82a7;
  border: 2px solid #3f82a7;
}

#know-more-3:hover {
  background-color: white;
  color: #3f82a7;
}

#know-more-4 {
  background-color: #ce5151;
  border: 2px solid #ce5151;
}

#know-more-4:hover {
  background-color: white;
  color: #ce5151;
}

.right-block {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.right-block img {
  border: solid 8px white;
  height: 450px;
}

.left-block img {
  border: solid 8px white;
}

.right-block p {
  text-align: justify;
}

.left-block p {
  text-align: justify;
}

.left-block {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin: 5%;
}

@keyframes bg {
  0% {
    background-size: 0 3px, 3px 0, 0 3px, 3px 0;
  }
  25% {
    background-size: 100% 3px, 3px 0, 0 3px, 3px 0;
  }
  50% {
    background-size: 100% 3px, 3px 100%, 0 3px, 3px 0;
  }
  75% {
    background-size: 100% 3px, 3px 100%, 100% 3px, 3px 0;
  }
  100% {
    background-size: 100% 3px, 3px 100%, 100% 3px, 3px 100%;
  }
}

.informations {
  background-color: #ececec;
  padding: 5%;
}

.services {
  background-color: white;
  padding: 5%;
}

.availability {
  background-color: #ececec;
  padding: 5%;
}

.home-contact {
  background-color: white;
  padding: 5%;
}

h2 {
  text-align: center;
  font-size: 36px;
}

.leaflet-container {
  width: 100%;
  height: 60vh;
}

.illustrations {
  transition: transform 0.2s;
  object-fit: cover;
}

.illustrations:hover {
  transform: scale(1.05);
  box-shadow: 8px 5px 5px rgb(102, 102, 102);
}

.left-block-text {
  border: 5px solid rgb(202, 202, 202);
  padding: 10px;
}

.right-block-text {
  border: 5px solid rgb(202, 202, 202);
  padding: 10px;
}

.home-h2 {
  color: rgb(66, 30, 30);
}

.cookie-consent-banner {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 2147483645;
  box-sizing: border-box;
  width: 100%;

  background-color: #f1f6f4;
}

.cookie-consent-banner__inner {
  max-width: 960px;
  margin: 0 auto;
  padding: 32px 0;
}

.cookie-consent-banner__copy {
  margin-bottom: 16px;
}

.cookie-consent-banner__header {
  margin-bottom: 8px;

  font-family: "CeraPRO-Bold", sans-serif, arial;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
}

.cookie-consent-banner__description {
  font-family: "CeraPRO-Regular", sans-serif, arial;
  font-weight: normal;
  color: #838f93;
  font-size: 16px;
  line-height: 24px;
}

.cookie-consent-banner__cta {
  box-sizing: border-box;
  display: inline-block;
  min-width: 164px;
  padding: 11px 13px;

  border-radius: 2px;

  background-color: #2ce080;

  color: #fff;
  text-decoration: none;
  text-align: center;
  font-family: "CeraPRO-Regular", sans-serif, arial;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
}

.cookie-consent-banner__cta--secondary {
  padding: 9px 13px;

  border: 2px solid #3a4649;

  background-color: transparent;

  color: #2ce080;
}

.cookie-consent-banner__cta:hover {
  background-color: #20ba68;
}

.cookie-consent-banner__cta--secondary:hover {
  border-color: #838f93;

  background-color: transparent;

  color: #22c870;
}

.cookie-consent-banner__cta:last-child {
  margin-left: 16px;
}

/* The switch - the box around the slider */

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}
/* Hide default HTML checkbox */

.switch input {
  display: none;
}
/* The slider */

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
input:checked + .slider {
  background-color: #2196f3;
}
input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}
input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
/* Rounded sliders */

.slider.round {
  border-radius: 34px;
}
.slider.round:before {
  border-radius: 50%;
}
