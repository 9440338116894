.footer-bar {
  display: flex;
  justify-content: space-between;
  background-color: rgb(32, 32, 32);
  align-items: center;

  bottom: 0;
  width: 100%;
}

.footer-button {
  background-color: rgb(32, 32, 32);
  border: none;
  color: white;
  padding: 16px 32px;
  text-align: center;
  font-size: 16px;
  margin: 4px 2px;
  opacity: 0.6;
  transition: 0.3s;
  display: inline-block;
  text-decoration: none;
  cursor: pointer;
}

.footer-button:hover {
  opacity: 1;
}

.credits {
  color: white;
}
