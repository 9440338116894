.pruner-banner-title {
  position: relative;
  text-align: center;
  color: white;
}

.pruner-site-title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-family: "Anton";
}

.pruner-blocks {
  display: flex;
}

.imgContainer {
  height: 500px;
  width: 400px;
}

svg {
  height: 50px;
  width: 50px;
}

.pruner-steps {
  background-color: white;
  padding: 5%;
}

.pruner-description {
  flex: 1;
  text-align: center;
}

.pruner-carousel {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pruner-carousel-img {
  position: relative;
  width: 400px;
  height: 550px;
  background: white;
  border-radius: 0% 0% 0% 0% / 0% 0% 0% 0%;
  color: white;
  box-shadow: 20px 20px rgba(0, 0, 0, 0.15);
  transition: all 0.4s ease;
}

.pruner-carousel-img:hover {
  border-radius: 0% 0% 50% 50% / 0% 0% 5% 5%;
  box-shadow: 10px 10px rgba(0, 0, 0, 0.25);
}
.bloclegends {
  display: flex;
  justify-content: center;
}

.legends {
  position: absolute;
  bottom: 40px;
  border-radius: 10px;
  background: #000;
  color: #fff;
  padding: 10px;
  font-size: 12px;
  text-align: center;
  opacity: 0.25;
  margin: auto;
}

.legends:hover {
  opacity: 1;
  transition: all 0.5s;
}

.gallery {
  display: flex;
  padding: 2px;
  transition: 0.3s;
}
.gallery:hover .gallery__image {
  cursor: pointer;
}
.gallery__column {
  display: flex;
  flex-direction: column;
  width: 25%;
}
.gallery__link {
  margin: 2px;
  overflow: hidden;
}

.gallery__link:hover .gallery__caption {
  opacity: 1;
}
.gallery__thumb {
  position: relative;
}
.gallery__image {
  display: block;
  width: 100%;
  transition: 0.3s;
  border-radius: 2%;
}
.gallery__image:hover {
  transform: scale(1.1);
}
.gallery__caption {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 25px 15px 15px;
  width: 100%;
  font-family: "Raleway", sans-serif;
  font-size: 16px;
  color: white;
  opacity: 0;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.5) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  transition: 0.3s;
}

.arrow-wrapper {
  display: flex;
  align-items: center;
}

/*************** * Arrow 1 ***************/
#arrow_1 .arrow {
  cursor: pointer;
  display: block;
  width: 21.216407355px;
  height: 42.43281471px;
  position: relative;
}
#arrow_1 .arrow span,
#arrow_1 .arrow:before,
#arrow_1 .arrow:after {
  background: black;
  content: "";
  display: block;
  width: 30px;
  height: 2px;
  position: absolute;
  top: calc(50% - (2px / 2));
}
#arrow_1 .arrow:before {
  transform: rotate(-45deg);
}
#arrow_1 .arrow:after {
  transform: rotate(45deg);
}
#arrow_1 .arrow span {
  width: 0;
}
#arrow_1 .arrow:hover span {
  width: 42.42px;
}
#arrow_1 .arrow.arrow--left span,
#arrow_1 .arrow.arrow--left:before,
#arrow_1 .arrow.arrow--left:after {
  left: 0;
  transform-origin: left 50%;
}
#arrow_1 .arrow.arrow--left:before,
#arrow_1 .arrow.arrow--left:after {
  transition: left 0.3s 0.05s;
}
#arrow_1 .arrow.arrow--left span {
  transition: width 0.3s, left 0.3s 0.05s;
}
#arrow_1 .arrow.arrow--left:hover span,
#arrow_1 .arrow.arrow--left:hover:before,
#arrow_1 .arrow.arrow--left:hover:after {
  left: -21.216407355px;
}
#arrow_1 .arrow.arrow--right span,
#arrow_1 .arrow.arrow--right:before,
#arrow_1 .arrow.arrow--right:after {
  right: 0;
  transform-origin: right 50%;
}
#arrow_1 .arrow.arrow--right:before,
#arrow_1 .arrow.arrow--right:after {
  transition: right 0.3s 0.05s;
}
#arrow_1 .arrow.arrow--right span {
  transition: width 0.3s, right 0.3s 0.05s;
}
#arrow_1 .arrow.arrow--right:hover span,
#arrow_1 .arrow.arrow--right:hover:before,
#arrow_1 .arrow.arrow--right:hover:after {
  right: -21.216407355px;
}

.pruner-contact {
  background-color: white;
  padding: 5%;
}

.pruner-h2 {
  color: #66a165;
}

.pruner-gallery {
  background-color: #ececec;
  padding: 5%;
}

.pruner-images-beforeafter {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-items: center;
  align-items: center;
}

.pruner-oneImage {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5%;
}


